//SCROLLBAR
&::-webkit-scrollbar{
  width: 0.7rem;
  height: 0.7rem;
  @media @tab-xl {
    width: 0.5rem;
    height: 0.5rem;
  }
}
&::-webkit-scrollbar-corner {
  background-color: transparent;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

&::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #9B9C9D;
}

&::-webkit-scrollbar-thumb:hover {
  background-color: lighten(#9B9C9D, 5%);
  border: 3px solid lighten(#9B9C9D, 5%);
}

// INPUTS
.ant-input-number {
  width: 100%;
  border-radius: 12px;
}

.ant-input,
.ant-input-number-input {
  border-radius: 12px;
  height: 50px;
  font-size: 1.5rem;
}

.ant-input-number-handler-wrap {
  border-radius: 12px;
}

.ant-select-selector,
.ant-select-selection-overflow {
  border-radius: 12px !important;
  height: 50px;
  font-size: 1.3rem;
}

.ant-form-item-label {
  padding-bottom: 4px !important;
}

.ant-form-item-label>label {
  color: white;
  font: normal 1.1rem Quicksand;

  @media @phone-sm {
    font: normal 1rem Quicksand;
  }
}

.ant-input-number-disabled > .ant-input-number-input-wrap > .ant-input-number-input, .ant-input[disabled] {
  color: rgba(0,0,0,0.5) !important;
  font-weight: bold;
}

.ant-carousel .slick-list .slick-slide {
  pointer-events: all;
}