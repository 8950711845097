.graph-results {
  display: flex;
  flex-direction: column;
  height: calc(100% - 48px);
  justify-content: space-between;

  h1 {
    color: #52BE6B;
    font-weight: 600;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 3rem;
    text-transform: uppercase;
    margin-bottom: 1rem;

    @media @desktop-xxl {
      font-size: 4.5rem;
    }

    @media @phone-sm {
      margin-bottom: 0;
    }
  }

  &__total {
    text-align: center;

    p {
      color: #203264;
      padding: 0;
      margin: 0;
      line-height: 1;
      font-weight: 500;
      font-size: 1.5rem;

      @media @desktop-xxl {
        font-size: 2rem;
      }
    }

    p+p {
      font-size: 2rem;

      @media @desktop-xxl {
        font-size: 3rem;
      }

      @media @phone-sm {
        font-size: 1.8rem;
      }
    }
  }

  &__pie {
    height: 25vw;

    @media @tab-xl {
      height: 35vw;
    }

    @media @tab-lg {
      height: 70vw;
    }

    @media @tab-md {
      height: 85vw;
    }
  }

  &__table {
    width: 100%;
    padding: 0 1.5rem 1rem;
    font-size: 1rem;

    @media @desktop-xxl {
      font-size: 1.2rem;
      padding-bottom: 2rem;
    }

    @media @phone-sm {
      font-size: 0.85rem;
      padding-bottom: 1rem;
    }
  }

  &__preview-colors {
    height: 15px;
    width: 15px;
    border-radius: 5px;
    margin-right: 5px;
  }

  &__table-bold {
    font-weight: bold;
    color: black;
  }

  &__table-lighter {
    font-weight: 300;
  }

  &__ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__header {
    max-height: 48px;
    padding: 1rem;
  }

  &__header-icon {
    object-fit: contain;
    height: 3vw;
    width: 3vw;
    transform: rotateZ(180deg);
    cursor: pointer;

    @media @tab-lg {
      height: 2rem;
      width: 2rem;
    }

    &:hover {
      filter: brightness(0.9);
    }
  }
}