.product-card {
  background-color: #928A95;
  border-radius: 20px;
  text-align: center;
  margin: 1rem .5rem;

  &:focus-visible {
    outline: none;
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 4vw;
    text-transform: uppercase;
    border-bottom: 1px solid #FFFFFF;

    @media @tab-lg {
      height: 6vw;
    }

    @media @tab-md {
      height: 10vw;
    }

    @media @phone-sm {
      min-height: 15vw;
    }

    h1 {
      color: #FFFFFF !important;
      margin: 0;
      font: bold 1.5vw Quicksand;
      text-transform: uppercase;

      @media @tab-lg {
        font: bold 2vw Quicksand;
      }

      @media @tab-md {
        font: bold 3.5vw Quicksand;
      }

      @media @phone-sm {
        font: bold 5vw Quicksand;
      }

      @media @phone-xs {
        font: bold 4.5vw Quicksand;
      }
    }
  }

  &__body {
    padding: 1rem 2rem;

    @media @phone-sm {
      padding: 1rem;
    }
  }

  &__tooltip-container {
    display: flex;
    flex-direction: column;
  }

  &__tooltip-icon {
    height: 1rem;
    width: 1rem;
    align-self: flex-end;
    transform: translate(1rem, .5rem);

    @media @phone-sm {
      transform: unset;
    }
  }
}