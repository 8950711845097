.app-layout {

  display: flex;
  flex-direction: row;
  height: 100vh;

  &__cover {
    @media @tab-lg {
      display: none;
    }
  }

  &__backgroundWeb {
    object-fit: cover;
    object-position: left;
    width: 100%;
    height: 100vh;
  }

  &__application-container {
    background-color: #EDF1FA;
    width: 45vw;
    min-width: 600px;
    max-width: 45%;
    height: 100%;
    margin-left: auto;

    right: 0;
    box-shadow: 0px 0px 4px black;
    overflow-y: auto;
    overflow-x: hidden;

    @media @tab-lg {
      min-width: unset;
      max-width: unset;
      width: 100%;
    }

  }

}