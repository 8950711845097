.home {
  &__header {
    background-image: url('../../../assets/svgs/header-background.svg');
    background-repeat: no-repeat;
    background-size: cover 100%;
    background-position: center top;
    width: 100%;
    height: 500px;

    @media @desktop-xxl {
      height: 600px;
    }

    @media @tab-lg {
      height: 450px;
    }

    @media @phone-sm {
      height: 400px;
    }

    @media @phone-xs {
      height: 300px;
    }

  }

  &__main-title {
    font: normal 4vw Quicksand;
    color: #0DFE01  !important;
    padding-top: 5rem;
    line-height: 1;
    margin: 0;
    text-align: center;

    @media @desktop-xxl {
      padding-top: 6rem;
    }

    @media @tab-lg {
      padding-top: 5rem;
      font: normal 4vw Quicksand;
    }

    @media @tab-md {
      padding-top: 6rem;
      font: normal 5.5vw Quicksand;
    }

    @media @phone-sm {
      padding-top: 5rem;
      font: normal 6.5vw Quicksand;
    }

    @media @phone-xs {
      padding-top: 3rem;
      font: normal 7vw Quicksand;
    }

    +p {
      font: bold 4vw Quicksand;
      color: #0DFE01  !important;
      padding: 0;
      margin: 0;
      text-align: center;

      @media @tab-lg {
        font: normal 4vw Quicksand;
      }

      @media @tab-md {
        font: bold 5.5vw Quicksand;
      }

      @media @phone-sm {
        font: bold 6.5vw Quicksand;
      }

      @media @phone-xs {
        font: bold 7vw Quicksand;
      }
    }
  }

  &__main-subtitle {
    font: normal 2.2vw Quicksand;
    color: @white  !important;
    margin: 2rem auto;
    max-width: 70%;
    text-align: center;

    @media @tab-lg {
      margin: 1rem auto;
      font: bold 3.5vw Quicksand;
    }

    @media @tab-md {
      max-width: 60%;
      font: normal 4.5vw Quicksand;
    }

    @media @phone-sm {
      margin: 1rem auto;
      max-width: 60%;
      font: normal 5vw Quicksand;
    }

    @media @phone-xs {
      font: normal 5.5vw Quicksand;
    }
  }

  &__seller-conversion {
    margin: 2rem;
  }

  &__tooltip-container {
    display: flex;
    flex-direction: column;
  }

  &__tooltip-icon {
    height: 1rem;
    width: 1rem;
    align-self: flex-end;

    transform: translate(-1.5rem, .5rem);

    @media @phone-sm {}
  }

  &__label-seller {
    text-transform: uppercase;
    color: #203264 !important;
    font-size: 1rem !important;
    font-weight: 800;
    max-width: 150px;

    label {
      color: #203264 !important;
    }
  }

  &__title {
    text-align: center;
    text-transform: uppercase !important;
    color: #203264 !important;
    font-size: 1.4rem;
    padding: 0rem 4rem;

    &--min {
      padding: 0rem 2rem;
    }

    h1 {
      text-transform: uppercase !important;
    }
  }

  &__button-results {
    display: block;
    margin: 2rem auto;
    border-radius: 50px;
    width: calc(100% - 3rem);
    height: 55px;
    background-color: #3AB185;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    font: bold 1.7vw Quicksand;
    color: @white;
    text-transform: uppercase;

    &:hover {
      filter: brightness(0.9);
      background-color: #3AB185;
    }

    @media @tab-lg {
      font: bold 3vw Quicksand;
    }

    @media @tab-md {
      font: bold 4.5vw Quicksand;
    }

    @media @phone-sm {
      font: bold 6.5vw Quicksand;
    }
  }

  &__container-channel {
    display: flex;
    justify-content: space-around;
  }

  &__input-number-custom {
    @media @phone-sm {
      max-width: 150px;
    }
  }
}