@import '~antd/dist/antd.less';
@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,200;0,400;0,600;0,700;0,800;0,900;1,300&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Quicksand:wght@300;400;500;600;700&display=swap');

//GENERAL
@import 'helpers/layout-helper';
@import 'helpers/variables';
@import 'helpers/base';

// VIEWS
@import 'views/Home/Home';
@import 'views/GraphResults/GraphResults';

// COMPONENTS
@import 'components/ToggleCardExtend/ToggleCardExtend';
@import 'components/ProductCard/ProductCard';
@import 'components/FixedCard/FixedCard';

// lAYOUTS
@import 'layouts/AppLayout';

body {
  margin: 0;
  font-family: 'Quicksand', 'Lato', 'Archivo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  orientation: portrait;

}

#root {
  --background-color-header: #203264;
}