#root .fixed-card {
  margin: auto 1.5rem !important;

  &__card {
    min-height: 4vw;
    margin-top: 1.3vw;
    display: flex;
    align-content: center;
    border-bottom: 1px solid white;
    border-radius: 20px 20px 0 0;

    @media @tab-lg {
      height: 6vw;
    }

    @media @tab-md {
      height: 10vw;
    }

    @media @phone-sm {
      margin-top: 4vw;
      min-height: 15vw;
    }
  }
  
  &__header {
    height: 100%;
    margin: auto;
    width: calc(100% - 2.5vw);
    color: white;

    @media @tab-lg {
      width: calc(100% - 5vw);
    }

    @media @phone-sm {
      width: calc(100% - 10vw);
    }
  }

  &__body {
    position: relative;
    background-color: @body-card;
    border-radius: 0 0 20px 20px;
    box-shadow: 0px 3px 6px #00000029;
  }

  &__body-children {
    width: 100%;
    padding: 1rem 3rem 1.5rem;

    @media @phone-sm {
      padding: 1rem 1rem 1.5rem;
    }
  }

  &__arrow-down {
    transition: 250ms;
    color: @primary-text-color;
    font-size: 2vw;
    justify-content: flex-end;

    @media @tab-lg {
      font-size: 3vw; 
    }

    @media @phone-sm {
      font-size: 7vw;
    }
  }

  &__arrow-up {
    transition: 250ms;
    color: @secondary-color;
    font-size: 2vw;
    transform: rotate(180deg);

    @media @tab-lg {
      font-size: 3vw; 
    }
    
    @media @phone-sm {
      font-size: 7vw;
    }
  }

  &__card-title {
    width: 95%;
    font: bold 1.5vw Quicksand;
    color: @white;
    text-transform: uppercase;

    @media @tab-lg {
      font: bold 2vw Quicksand; 
    }

    @media @tab-md {
      font: bold 3.5vw Quicksand; 
    }

    @media @phone-sm {
      font: bold 5vw Quicksand; 
    }

    @media @phone-xs {
      font: bold 4.5vw Quicksand; 
    }
  }

  &__button-more {
    display: block;
    margin: 3vw auto;
    height: 3vw;
    width: 25vw;
    font-size: 1.3vw;

    @media @tab-md {
      font-size: 2vw;
      margin: 3vw auto;
      height: 4vw;  
    }

    @media @phone-sm {
      font-size: 4vw;
      margin: 5vw auto;
      height: 8vw;
      width: 45vw;
    }
  }
}